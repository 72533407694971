import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Eula = () => (
  <Layout>
    <SEO title="Terms" />
    <section className="contentbox wrap --wideblue">
      <div class="txt_full_box text_left termsbox">
        <h2>
          Cookie Policy<span> for Boatpilot</span>
        </h2>

        <h3>WHAT ARE COOKIES</h3>

        <p>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality. For more general
          information on cookies see the Wikipedia article on HTTP Cookies.
        </p>

        <h3>HOW WE USE COOKIES</h3>

        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>

        <h3>DISABLING COOKIES</h3>

        <p>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies.
        </p>

        <h3>THE COOKIES WE SET</h3>

        <ul>
          <li>• Account related cookies </li>
          <li>
            • If you create an account with us then we will use cookies for the
            management of the signup process and general administration. These
            cookies will usually be deleted when you log out however in some
            cases they may remain afterwards to remember your site preferences
            when logged out.
          </li>
          <li>• Login related cookies</li>
          <li>
            • We use cookies when you are logged in so that we can remember this
            fact. This prevents you from having to log in every single time you
            visit a new page. These cookies are typically removed or cleared
            when you log out to ensure that you can only access restricted
            features and areas when logged in.
          </li>
          <li>• Email newsletters related cookies</li>
          <li>
            • This site offers newsletter or email subscription services and
            cookies may be used to remember if you are already registered and
            whether to show certain notifications which might only be valid to
            subscribed/unsubscribed users.
          </li>
          <li>• Forms related cookies</li>
          <li>
            • When you submit data to through a form such as those found on
            contact pages or comment forms cookies may be set to remember your
            user details for future correspondence.
          </li>
        </ul>
        <h3>THIRD PARTY COOKIES</h3>

        <p>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </p>

        <ul>
          <li>
            • This site uses Google Analytics which is one of the most
            widespread and trusted analytics solution on the web for helping us
            to understand how you use the site and ways that we can improve your
            experience. These cookies may track things such as how long you
            spend on the site and the pages that you visit so we can continue to
            produce engaging content.
          </li>
          <li>
            • For more information on Google Analytics cookies, see the official
            Google Analytics page.
          </li>
          <li>
            • Several partners advertise on our behalf and affiliate tracking
            cookies simply allow us to see if our customers have come to the
            site through one of our partner sites so that we can credit them
            appropriately and where applicable allow our affiliate partners to
            provide any bonus that they may provide you for making a purchase.
          </li>
          <li>
            • We also use social media buttons and/or plugins on this site that
            allow you to connect with your social network in various ways. For
            these to work the following social media sites including;
            Facebook.com, Google.com, will set cookies through our site which
            may be used to enhance your profile on their site or contribute to
            the data they hold for various purposes outlined in their respective
            privacy policies.
          </li>
        </ul>
        <h3>MORE INFORMATION</h3>

        <p>
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site. This Cookies
          Policy was created with the help of the Generator of GDPR Cookies
          Policy and the GDPR Privacy Policy.{" "}
        </p>

        <p>
          However if you are still looking for more information then you can
          contact us through one of our preferred contact methods: Email:
          info@boatpilot.me
        </p>
      </div>
    </section>
  </Layout>
);

export default Eula;
